@tailwind base;
@tailwind components;
@tailwind utilities;

/* @import 'react-calendar/dist/Calendar.css'; */


body {
  @apply font-montserrat uppercase text-forrest-green bg-beige
}

.public-DraftEditor-content{
  @apply normal-case
}

.DraftEditor-root, .DraftEditor-editorContainer,.public-DraftEditor-content{
  @apply h-full
}

.DraftEditor-root .public-DraftStyleDefault-ul{
  @apply my-0 normal-case
}

.react-calendar__navigation{
  @apply hidden
}

.react-calendar__tile{
  @apply py-[4px] 
}

.react-calendar__tile--now.react-calendar__month-view__days__day,.react-calendar__tile--now.react-calendar__month-view__days__day--weekend{
  @apply relative text-beige overflow-visible
}

.react-calendar__tile--now::after{
  @apply content-[''] absolute top-1/2 -translate-y-[50%] left-1/2 -translate-x-1/2 w-6 h-6 rounded-full bg-forrest-green opacity-70 -z-10
}
 
.react-calendar__month-view{
  @apply border-y border-stone-400 py-6 min-h-[211px] my-auto
}

.react-calendar{
  @apply w-fit mx-auto pointer-events-none
}

.react-calendar__month-view, .react-calendar__month-view > div {
  @apply  w-full max-w-[500px] mx-auto
}

.react-calendar__month-view__days__day--weekend{
  @apply text-red-500
}

.react-calendar__month-view__days__day--neighboringMonth{
  @apply invisible
}

.react-calendar__month-view__weekdays{
  @apply mb-6
}

.react-calendar__month-view__weekdays__weekday {
  @apply w-full text-center text-[12px] font-semibold capitalize
}

.react-calendar__month-view__weekdays__weekday abbr{
  @apply no-underline
}

.react-calendar__month-view__days{
  @apply text-[12px] font-semibold gap-y-1
}

.btn{
  @apply border border-forrest-green px-4 py-1 rounded hover:text-white hover:bg-forrest-green transition
}